





































































import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'Appearance',
  data() {
    return {
      theme: this.$vuetify.theme.dark,
    };
  },
  computed: {
    ...mapGetters(['settings']),
  },
  methods: {
    updateLanguage(val: string) {
      this.$store.dispatch('updateSettings', [
        'common',
        {
          language: val,
        },
      ]);
    },
    handleFontSizeChange(v: number) {
      document.documentElement.style.fontSize = `${16 * v}px`;
      this.$store.dispatch('updateSettings', ['prosys', { font_size: v }]);
    },
    handleFontFamilyChange(v: string) {
      document.documentElement.style.fontFamily = `"${v}", sans-serif`;
      this.$store.dispatch('updateSettings', ['prosys', { font_family: v }]);
    },
    handleBackgroundChange(v: string) {
      this.$store.dispatch('updateSettings', ['common', { background_color: v }]);
    },
  },
  watch: {
    theme(v) {
      const color_theme = v ? 'Dark' : 'Light';
      this.$store.dispatch('updateSettings', ['common', { color_theme }]);
      this.$vuetify.theme.dark = v;
    },
  },
});
