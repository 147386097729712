






















import Vue from 'vue';
export default Vue.extend({
  name: 'Settings',
  computed: {
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
});
